import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDeleteClubRequests, ClubDelete } from "../../../helpers/Redux/api";
import Pagination from "react-mui-pagination";
import noimage from "../../../assets/images/users/noimg.png";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import { ImBin2 } from "react-icons/im";
import { IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";
function DeleteClubRequest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [total_count, setTotalCount] = useState(0);
  const [recordperpage, setRecordperpage] = useState(10);
  const [page, setPage] = useState(1);
  useEffect(() => {
    getList();
  }, []);
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const getList = async () => {
    try {
      const res = await dispatch(getDeleteClubRequests());
      if (res?.payload?.status === true) {
        setList(res?.payload?.data);
        setTotalCount(res?.payload?.totalCount);
      } else {
        setList([]);
      }
      console.log(res);
    } catch (error) {
      console.log("Error :- ", error);
    }
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure want to delete club?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0373FF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete ",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          dispatch(ClubDelete(id)).then((res) => {
            if (res?.payload?.success === true) {
              // Swal.fire({
              //     title: "Deleted!",
              //     text: "Member Deleted successfully.",
              //     icon: "success"
              //   });
              toast.success(res?.payload?.message);

              setList(
                list?.filter((elm) => elm?.id != res?.payload?.deleted_id)
              );
            } else {
              toast.error(res?.payload?.message);
            }
          });
        } catch (error) {
          console.log(error, "error");
        }
      }
    });
  };
  return (
    <>
      <div className="list_page_container">
        <div className="page_name_heading_cont">
          <div className="container">
            <h5>Club Delete Requests</h5>
          </div>
        </div>
        <div className="py-5">
          <div className="container">
            <div className="shadow-box">
              <div className="table_container table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Photo</th>
                      <th>Club Name</th>
                      <th>Email</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.map((res, i) => (
                      <tr key={i}>
                        <td className="common_class_td_width">
                          <img
                            src={res?.club_logo ? res.club_logo : noimage}
                            alt="profile_image"
                            className="user_profile_img "
                          />
                        </td>
                        <td className="common_class_td_width">{res?.name}</td>
                        <td className="common_class_td_width">{res?.email}</td>
                        <td className="common_class_td_width">
                          {res?.description}
                        </td>
                        <td>
                          <div className="action_button_cont">
                            <Tooltip title="View">
                              <button
                                className="view_btn"
                                onClick={() =>
                                  navigate(`/club/view/${res?.id}`, {
                                    state: { page },
                                  })
                                }
                              >
                                <IoMdEye />
                              </button>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <button
                                className="delete_btn"
                                onClick={() => handleDelete(res?.id)}
                              >
                                <ImBin2 />
                              </button>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {total_count >= 10 && (
                <div className="d-flex justify-content-end align-items-center my-3">
                  <Pagination
                    page={page}
                    setPage={handlePageChange}
                    total={total_count}
                    perPage={recordperpage}
                    className="pagination"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DeleteClubRequest;
