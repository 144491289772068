import React, { useState, useEffect } from "react";
import "./list.css";
import noimage from "../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import {
  UserDelete,
  UserExportToExcel,
  UserLists,
  userStatusUpdate,
} from "../../../helpers/Redux/api";
import Tooltip from "@mui/material/Tooltip";
import Pagination from "react-mui-pagination";
import { IoMdEye } from "react-icons/io";
import { ImBin2 } from "react-icons/im";
import { MdModeEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const UserList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const previousPageCount = location.state?.page;

  console.log(previousPageCount, "page");

  const [list, setList] = useState([]);

  const [total_count, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [recordperpage, setRecordperpage] = useState(10);
  const [search, setSearch] = useState("");

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleUpdateStatus = (status, id) => {
    const sendStatus = status == true ? false : true;

    var requestOptions = {
      user_id: id,
      is_active: sendStatus,
    };
    try {
      dispatch(userStatusUpdate(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          getUsersData();
          toast.success(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getUsersData = () => {
    const requestOption = {
      page: page,
      limit: recordperpage,
      search: search,
    };
    try {
      dispatch(UserLists(requestOption)).then((res) => {
        if (res?.payload?.success === true) {
          setList(res?.payload?.users);
          setTotalCount(res?.payload?.totalCount);
        } else {
          setList([]);
        }
      });
    } catch (error) {
      console.log("user list Error :- ", error);
    }
  };
  useEffect(() => {
    getUsersData();
    // exportToExcelUserData();
  }, [page, search]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure want to delete member?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0373FF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete ",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          dispatch(UserDelete(id)).then((res) => {
            if (res?.payload?.success === true) {
              // Swal.fire({
              //     title: "Deleted!",
              //     text: "Member Deleted successfully.",
              //     icon: "success"
              //   });
              toast.success(res?.payload?.message);

              setList(
                list?.filter((elm) => elm?.id != res?.payload?.deleted_id)
              );
            } else {
              toast.error(res?.payload?.message);
            }
          });
        } catch (error) {
          console.log(error, "error");
        }
      }
    });
  };
  const exportToExcelUserData = () => {
    try {
      dispatch(UserExportToExcel()).then((res) => {
        if (res?.payload?.success === true) {
          const data = res.payload.data;
          const fileName = "UserList";
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(data);

          // Append the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

          // Generate the Excel file and trigger download
          XLSX.writeFile(workbook, `${fileName}.xlsx`);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <>
      <div className="list_page_container">
        <div className="page_name_heading_cont">
          <div className="container d-flex justify-content-between">
            <h5>Users</h5>
            <button onClick={exportToExcelUserData} className="m-0 export-btn" >Export</button>
          </div>
        </div>
        <div className="py-md-5">
          <div className="container">
            <div className="shadow-box">
              <div className="top_search text-end mb-4">
                <input
                  type="text"
                  placeholder="Type here..."
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                ></input>
              </div>
              <div className="table_container table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Photo</th>
                      <th>Name</th>
                      <th>Email </th>
                      <th>Mobile</th>
                      <th>Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.map((res, i) => (
                      <tr key={i}>
                        <td>
                          {" "}
                          <img
                            src={
                              res?.profile_picture
                                ? res.profile_picture
                                : noimage
                            }
                            alt="profile_image"
                            className="user_profile_img "
                          />
                        </td>

                        <td>
                          {res && res.full_name && res.full_name.length > 1
                            ? res.full_name.charAt(0).toUpperCase() +
                              res.full_name.slice(1)
                            : ""}
                        </td>

                        <td className="common_class_td_width">{res?.email}</td>
                        <td className="common_class_td_width">
                          {" "}
                          {res?.mobile
                            ? ` ${res?.country_code ? res?.country_code : ""} ${
                                res?.mobile
                              }`
                            : "NA"}
                        </td>
                        <td>
                          <Tooltip title="Click to change status">
                            <button
                              className={`${
                                res?.is_active ? "active" : "inactive"
                              }`}
                              onClick={() =>
                                handleUpdateStatus(res?.is_active, res?.id)
                              }
                            >
                              {" "}
                              {res?.is_active ? "Active" : "Inactive"}{" "}
                            </button>
                          </Tooltip>
                        </td>
                        <td>
                          <div className="action_button_cont">
                            <Tooltip title="Edit">
                              <button
                                className="edit_btn"
                                onClick={() =>
                                  navigate(`/user/edit/${res?.id}`)
                                }
                              >
                                <MdModeEdit />
                              </button>
                            </Tooltip>
                            <Tooltip title="View">
                              <button
                                className="view_btn"
                                onClick={() =>
                                  navigate(`/user/view/${res?.id}`, {
                                    state: { page },
                                  })
                                }
                              >
                                <IoMdEye />
                              </button>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <button
                                className="delete_btn"
                                onClick={() => handleDelete(res?.id)}
                              >
                                <ImBin2 />
                              </button>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {total_count >= 10 && (
                <div className="d-flex   justify-content-end align-items-center my-3 ">
                  <Pagination
                    page={page}
                    setPage={handlePageChange}
                    total={total_count}
                    perPage={recordperpage}
                    className="pagination"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
