import React, { useEffect, useState } from "react";
import './edit.css';
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import header_image_thumg from '../../../../src/assets/images/users/noimg.png';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { vendorUpdate, viewVendorDetail } from "../../../helpers/Redux/api"; 
import { toast } from "react-toastify";

const EditVendor = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const previousPageCount = location.state?.page;
    const [showimage, setShowImage] = useState(header_image_thumg);
    const [startDate, setStartDate] = useState(new Date());
    const [sendImage, setSendImage] = useState(null);
    const [status, setstatus] = useState(0);

    const [loading, setLoading] = useState(false);

    const vendor_Id = location.pathname?.split("/")[3];

    const handleImageUpload = (e) => {
      let file = e.target.files[0];
      setShowImage(URL.createObjectURL(file));
      setSendImage(file);
    };
    
    const [initialValue, setInitialValue] = useState({
          vendorName: '',
          email: '',
          address: '',
          zipCode: '',
          mobile: '',
          gstNo: '',
          panCard: '',
          chatRestriction: 0,
          vendorLogo: '',
          deliveryFee:''
      });
  console.log(initialValue)
    
    const handlegetdata = () => {
      setLoading(true);
      try {
        dispatch(viewVendorDetail(vendor_Id)).then((res) => {
          if (res?.payload?.status === true) {
            setLoading(false);
              setInitialValue({
                vendorName: res?.payload?.data[0].name,
                email: res?.payload?.data[0].email,
                address: res?.payload?.data[0].address,
                zipCode: res?.payload?.data[0].zip_code,
                mobile: res?.payload?.data[0].mobile,
                gstNo: res?.payload?.data[0].gst_no,
                panCard: res?.payload?.data[0].pan_card,
                chatRestriction: res?.payload?.data[0].chat_restriction,
                deliveryFee:res?.payload?.data[0].delivery_fee
            });
            if (res?.payload?.data[0]?.vendor_logo) {
              setShowImage(
                res?.payload?.data[0]?.vendor_logo
              );
              setSendImage(
                res?.payload?.data[0]?.vendor_logo
              );
            }
          } else {
            setLoading(false);
            setInitialValue(initialValue);
          }
        });
      } catch (error) {
        console.log(error, "from view Vendor details");
      }
    };
    useEffect(() => {
      handlegetdata();
    }, []);
  
    const handleSubmit = (values, { resetForm }) => {
      console.log(sendImage, 'sendImage')
  
      // Create a new FormData object
      const formData = new FormData();
  
      // Append form values to FormData
      formData.append('name', values.vendorName);
        formData.append('email', values.email);
        formData.append('address', values.address);
        formData.append('zip_code', values.zipCode);
        formData.append('mobile', values.mobile);
        formData.append('gst_no', values.gstNo);
        formData.append('pan_card', values.panCard);
        formData.append('vendor_logo', sendImage); // Append the image file
        formData.append('vendor_id', vendor_Id);
        formData.append('delivery_fee', values.deliveryFee);
  
      // Create request options with the FormData
      const requestOptions = {
        method: 'POST',
          body: formData,
          headers: {
          'authorization': `${localStorage.getItem('token')}`  
        }
        
      };
  
      try {
        dispatch(vendorUpdate(requestOptions)).then((res) => {
    
          if (res?.payload?.status === true) {
            toast.success(res?.payload?.message);
            navigate(-1)
            resetForm()
          } else {
            toast.error(res?.payload?.message);
          }
        });
  
      } catch (error) {
        console.log(error, 'error')
      }
    }
  
    const validationscheme = Yup.object({
      vendorName: Yup.string().required(" Name is required"),
      email: Yup.string().required(" Email is required"),
      email: Yup.string().email(" Invalid email address"),
      address: Yup.string().required(" Address  is required"),
      zipCode: Yup.string().required("Zip code is required"),
      mobile: Yup.string().required("Phone is required"),
      gstNo: Yup.string().required("GST No is required"),
      panCard: Yup.string().required("Pan card is required"),
      // vendorLogo: Yup.string().required("Pan card is required"),
    });

    return (
      <div className="edit-section">
        <div className="page_name_heading_cont">
            <div className='container'>
                <h5>Edit Vendor Details</h5>
            </div>
        </div>
        <div className='py-5'>
            <div className='container'>
                <div className="edit-form shadow-box">
              {!loading &&
                <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                validationSchema={validationscheme}
                >
                  {(formik)=> (
                  <Form>
                    <div className='row'>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="vendorName">Vendor Name</label>
                                <Field className="form-control" placeholder="Your Vendor Name" name="vendorName"/>
                                <ErrorMessage name='vendorName' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="email">Email</label>
                                <Field className="form-control" placeholder="Email" name="email" />
                                <ErrorMessage name='email' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="address">Address</label>
                                <Field className="form-control" placeholder="Address" name="address" />
                                <ErrorMessage name='address' component="div" className="text-danger" />
                            </div>
        
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="zipCode">Zip code</label>
                                <Field className="form-control" placeholder="Zip Code" name="zipCode" />
                                <ErrorMessage name='zipCode' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="mobile">Phone</label>
                                <Field className="form-control" placeholder="Phone" name="mobile"/>
                                <ErrorMessage name='mobile' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="gstNo">GST No.</label>
                                <Field className="form-control" placeholder="GST no." name="gstNo" />
                                <ErrorMessage name='gstNo' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="panCard">Pan card</label>
                                <Field className="form-control" placeholder="Pan card" name="panCard" />
                                <ErrorMessage name='panCard' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="deliveryFee">Delivery fee</label>
                                <Field className="form-control" placeholder="Delivery fee" name="deliveryFee" required="" />
                                <ErrorMessage name='deliveryFee' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-12'>
                                <div className="upload_image_container">
                                    <img src={ showimage} alt="logo" className="img-fluid uploaded_image " />
                                    <label htmlFor="upload-photo2">Upload Photo</label>
                                    <input type="file" name="vendorLogo" id="upload-photo2" onChange={handleImageUpload} accept=".pjg, .png, .jpeg" />
                                    <ErrorMessage name='vendorLogo' component="div" className="text-danger" />
                                </div>
                            </div>
                        </div>
                    <div className="row justify-content-center">
                      <div className="col-md-3">
                        <button type="submit" name="back" className="btn btn-success w-100 mt-4">Submit</button>
                      </div>
                      <div className="col-md-3">
                        <button type="button" className="btn btn-secondary w-100 mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</button>
                      </div>
                    </div>
                  </Form>
                  )}
                </Formik>
              }
                </div>
            </div>    
        </div>
      </div>
    );
  }
  
  export default EditVendor;