import React from "react";
import "./login.scss"
import { Link, useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons";
import { useState } from "react";
import axios from "axios";
// import { AuthContext } from "../helpers/AuthContext";
import { useContext } from "react";
import {  toast } from 'react-toastify';
import { Formik , Form , Field ,ErrorMessage } from "formik";
import * as Yup from 'yup';
import brand_logo from '../../assets/brandlogo/logo-white.svg';
import logo_white from "../../assets/Homepage_image/logo-white.svg";
import { RiEyeCloseLine } from "react-icons/ri";
import { IoEyeOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { login } from "../../helpers/Redux/api";

const ClubLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
 
  const [showpassword , setShowPassword ]= useState(false)
const initialValues = {
  email :'',
  password: '',
  role :2
}
const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Invalid email address'),
  password: Yup.string().required('Password is required')
});


const onSubmit = (values , {resetForm}) =>{
  try {
    dispatch(login(values)).then((res) => {
      if(res?.payload?.success === true){
        resetForm()
        toast.success(res?.payload?.message)
        navigate('/dashboard')
        localStorage.setItem('token' , res?.payload?.token)
        localStorage.setItem('user_id', res?.payload?.admin?.id)
        localStorage.setItem('userType', res?.payload?.userType)
        

     }
     else {
        toast.error(res?.payload?.message)

     }
    })
  }
  catch (error) {
    console.log(error)
  }
}


  return (
    <div className=" min-vh-100  login_page"  >
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={7} className="d-flex justify-content-center" >
            <CCardGroup >
              <div className=" login_card"  >
                <div>
                  <div className="login_from" >
                    <img src={logo_white} alt="Brand logo" className="login_page_brand_logo" />
                    {/* <h1 >Welcome to <br />  The Biker Company</h1> */}
                    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
                      <Form >
                          <CCard className="login_field_cont">
                        <div className=" d-flex flex-column align-items-start">
                            <label htmlFor="email" style={{color :'#FFF'}} >Email <span className="text-danger">*</span></label>
                            <Field placeholder="Enter email address" name='email' type='email' className='input_field_login' id='email' autoComplete="new-email"/>
                            <ErrorMessage component={'div'} className="text-danger" name="email" />
                        </div>
                        <div>
                          <div className=" d-flex flex-column align-items-start" style={{position :'relative'}}>
                            <label htmlFor="email" style={{color :'#FFF'}} >Password <span className="text-danger">*</span></label>
                            <Field type={showpassword ? 'text' : 'password'} placeholder="Enter Password" className='input_field_login  ' name='password'/>
                            {
                              showpassword ? <IoEyeOutline onClick={() => setShowPassword(!showpassword)} className="showpassword_icons" />
                              : <RiEyeCloseLine onClick={() => setShowPassword(!showpassword)} className="showpassword_icons" />
                            }
                          </div>
                          <ErrorMessage component={'div'} className="text-danger text-start " name="password" />
                        </div>
                        </CCard>
                        <CRow className="flex-column align-items-center gap-5">
                          <CCol xs={12}>
                            <button className="Submit_button" type="submit">
                              Login
                            </button>
                          </CCol>
                          <CCol xs={12} >
                            <Link to='/forgotpassword'  className="text-light  " style={{ textDecoration:'underline' }}>
                              Forgot password?
                            </Link>
                          </CCol>
                        </CRow>
                            
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
             
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ClubLogin;
