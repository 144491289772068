import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getOrder } from "../../../helpers/Redux/api";
import Tooltip from '@mui/material/Tooltip';
import Pagination from "react-mui-pagination";
import { IoMdEye } from "react-icons/io";
import { toast } from "react-toastify";

const statusColors = {
    "READY TO SHIP": "#4CAF50",
    "NEW": "#2196F3",          
    "PICKUP SCHEDULED": "#FFC107",   
    "PICKED UP":"#FF9800", 
    "IN TRANSIT":"#FF9800",
    "OUT FOR DELIVERY":"#4bae96",
    "DELIVERED": "#8BC34A",    
    "PICKUP EXCEPTION": "#FF9800",
    "RETURNED": "#F44336",     
    "CANCELED": "#9E9E9E",
    "RETURN PENDING":"#2196F3",
    "RETURN PICKUP GENERATED":"#FFC107",
    "RETURN OUT FOR PICKUP":"#FF9800",
    "RETURN PICKED UP":"#c57600",
    "RETURN IN TRANSIT":"#FF9800",
    "RETURN DELIVERED":"#8BC34A",
    "RETURN CANCELLED":"#9E9E9E",
    "UNDELIVERED":"#cb4653"
};

const OrderList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const location = useLocation();
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getOrdersData = () => {
        const requestOptions = {
            page: page,
            limit: 10, 
            search: search,
            vendor_id: localStorage.getItem('user_id')
        };
        try {
            dispatch(getOrder(requestOptions)).then((res) => {
                if (res?.payload?.status === true) {
                    setList(res?.payload?.orderDetails);
                    setTotalCount(res?.payload?.total_count);  // Update total count for pagination
                } else {
                    setList([]);
                    // toast.error(res?.payload?.message);
                }
            });
        } catch (error) {
            console.log('Order list error:', error);
        }
    };
    useEffect(() => {
        console.log('call');
        console.log(location.state);
        const pageFromState = location?.state?.previousPageCount || 1;
        setPage(pageFromState);
    }, []);
    useEffect(() => {
        getOrdersData();
    }, [page, search]);

    return (
        <>
            <div className="list_page_container">
                <div className="page_name_heading_cont">
                    <div className="container">
                        <h5>Orders</h5>
                    </div>
                </div>
                <div className="py-md-5">
                    <div className="container">
                        <div className="shadow-box">
                            <div className="table_container text-center table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Order ID</th>
                                            <th>Customer Name</th>
                                            <th>Order Date</th>
                                            <th>Total Amount</th>
                                            <th>Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list?.map((order, i) => {
                                            const statusColor = statusColors[order?.data?.status] || "rgb(160 154 52)"; 
                                            return (
                                                <tr key={i}>
                                                    <td>{order?.data?.id}</td>
                                                    <td>{order?.data?.customer_name}</td>
                                                    <td>{order?.data?.order_date}</td>
                                                    <td>{order?.data?.total}</td>
                                                    <td>
                                                        <button className="fw-bold btn-primary btn-sm p-1 px-3 text-light rounded-pill border-0" style={{ background: statusColor,cursor:'default', pointerEvents: 'none' }}>
                                                            {order?.data?.status}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <div className="action_button_cont">
                                                            <Tooltip title="View">
                                                                <button 
                                                                    className="view_btn" 
                                                                    onClick={() => navigate(`/order/view/${order?.data?.id}`, { state: { page ,isReturn:order?.data?.is_return == 1 ? true : false} })}
                                                                >
                                                                    <IoMdEye />
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {total_count > 10 && 
                                <div className="d-flex justify-content-end align-items-center my-3">
                                    <Pagination  
                                        page={page} 
                                        setPage={handlePageChange}
                                        total={total_count} 
                                        perPage={10} 
                                        className='pagination'  
                                    />
                                </div>
                            }
                        </div>    
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderList;
