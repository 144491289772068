import React, { useState, useEffect } from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  ColorLists,
  SizeLists,
  VendorLists,
  categoryList,
  productAdd,
  productUpdate,
  productView,
} from "../../../helpers/Redux/api";
import "react-datepicker/dist/react-datepicker.css";
import { FaUpload } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MultiSelect } from "react-multi-select-component";
import * as Yup from 'yup'
import { TiDeleteOutline } from "react-icons/ti";


const EditProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const productId = location.pathname.split('/')[3]
  const previousPageCount = location.state?.page;
  const [categorylist, setCategoryList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [sizeList, setSizeList] = useState([]);


  const [loading, setLoading] = useState(false);


  // const validationSchemas = Yup.object({
  //   variantRow: Yup.array().of(
  //     Yup.object({
  //       image: Yup.array()
  //         .of(
  //           Yup.mixed()
            
  //         )
  //         .test(
  //           "minLength",
  //           "You must upload at least 2 images",
  //           (value) => value && value.length >= 2
  //         )
  //         .test(
  //           "maxLength",
  //           "You can upload a maximum of 4 images",
  //           (value) => value && value.length <= 4
  //         )
  //     })
  //   )
  // })

  const [initialValue , setInitialValue] = useState ({
    productName: "",
                category: "",
                vendor: "",
                description: "",
                variantRow: [
                  {
                    id: "",
                    gender: "",
                    color: "",
                    size: [],
                    weight: "",
                    upc: "",
                    sku: "",
                    price: "",
                    quantity: "",
                    discount: "",
                    privacy: "",
                    image: [],
                    limit: "",
                  },
                ],
  })

  const getProductDetail = async () => {
    setLoading(true)
    try {
      const response = await dispatch(productView(productId));
  
      if (response?.payload?.status === true) {
        const product = response.payload.payload[0];
        console.log(product , 'product')
  
        const variants = product.variants.map(variant => ({
          variant_id: variant.variant_id,
          gender: variant.gender,
          color: variant.color_id,
          size: variant.sizes.map(size => ({
            value: size.size_id,
            label: size.size_name,
          })),
          
          upc: variant.upc,
          sku: variant.sku,
          weight: variant.weight,
          price: variant.price,
          quantity: variant.quantity,
          discount: variant.discount,
          privacy: variant.privacy,
          limit: variant.limit,
          image: variant.images,
          imagePreview:variant.images,
        }));
  
        setInitialValue({
          productName: product.name,
          category: product.category_id,
          vendor: product.vendors_id,
          description: product.description,
          variantRow: variants,
        });
    setLoading(false)

      } else {
    setLoading(false)

      }
      
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    formData.append("name", values.productName);
    formData.append("product_id", productId);
    formData.append("category_id", values.category);
    formData.append("vendor_id", values.vendor);
    formData.append("description", values.description);

    values.variantRow?.forEach((variant, i) => {
      formData.append(`variants[${i}][variant_id]`,variant.variant_id );
      formData.append(`variants[${i}][gender]`,variant.gender );
    formData.append(`variants[${i}][color_id]`,variant.color );
    formData.append(`variants[${i}][sku]`,variant.sku );
    formData.append(`variants[${i}][weight]`, variant.weight);
    formData.append(`variants[${i}][upc]`, variant.upc);
    formData.append(`variants[${i}][quantity]`, variant.quantity);
      formData.append(`variants[${i}][discount]`, variant.discount);
      formData.append(`variants[${i}][privacy]`, variant.privacy);
      formData.append(`variants[${i}][limit]`,variant.limit );
      formData.append(`variants[${i}][price]`, variant.price);
      
      const size =   variant.size.map(size => (
      size.value
      ))
         
      formData.append(`variants[${i}][sizes]`, JSON.stringify(size));

      variant.image.forEach(image => {
        formData.append(`variants[${i}][images][]`, image);
      })
    });
    // Create request options with the FormData
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    };

    try {
      dispatch(productUpdate(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          navigate(-1);
          resetForm();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getUsersData = () => {
    try {
      dispatch(categoryList()).then((res) => {
        if (res?.payload?.status === true) {
          setCategoryList(res?.payload?.data);
        } else {
          setCategoryList([]);
        }
      });
    } catch (error) {
      console.log("user list Error :- ", error);
    }
  };

  const getVendorsData = () => {
    const requestOption = {
      page: "",
      limit: "",
      search: "",
    };
    try {
      dispatch(VendorLists(requestOption)).then((res) => {
        if (res?.payload?.status === true) {
          setVendorList(res?.payload?.data);
        } else {
          setVendorList([]);
        }
      });
    } catch (error) {
      console.log("user list Error :- ", error);
    }
  };

  const getColorsData = () => {
    const requestOption = {
      page: "",
      limit: "",
      search: "",
    };
    try {
      dispatch(ColorLists(requestOption)).then((res) => {
        if (res?.payload?.status === true) {
          setColorList(res?.payload?.data);
        } else {
          setColorList([]);
        }
      });
    } catch (error) {
      console.log("user list Error :- ", error);
    }
  };
  const getSizesData = () => {
    const requestOption = {
      page: "",
      limit: "",
      search: "",
    };
    try {
      dispatch(SizeLists(requestOption)).then((res) => {
        if (res?.payload?.status === true) {
          setSizeList(res?.payload?.data);
        } else {
          setSizeList([]);
        }
      });
    } catch (error) {
      console.log("user list Error :- ", error);
    }
  };
  useEffect(() => {
    getVendorsData();
    getUsersData();
    getColorsData();
    getSizesData();
    getProductDetail();
  }, []);

  const optionsSize = sizeList.map((res) => ({
    value: res?.id,
    label: res?.size_name,
  }));


  const validationscheme = Yup.object({
    productName: Yup.string().required('Product name is required'),
    category: Yup.string().required('Category is required'),
    vendor: Yup.string().required('Vendor is required'),
    description: Yup.string().required('Description is required'),
    variantRow: Yup.array().of(
      Yup.object({
        // gender: Yup.string().required('Gender is required'),
        // color: Yup.string().required('Color is required'),
        // size: Yup.array()
        // .of(
        //   Yup.object().shape({
        //     value: Yup.string().required('Value is required'),
        //     label: Yup.string().required('Label is required'),
        //   })
        // )
        // .required('Size array is required')
        // .min(1, 'At least one size is required'),
        weight: Yup.string().required('Weight is required'),
        upc: Yup.string().required('UPC is required'),
        sku: Yup.string().required('SKU is required'),
        price: Yup.number().required('Price is required').positive('Price must be a positive number'),
        quantity: Yup.number().required('Quantity is required').min(0, 'Quantity cannot be negative'),
        // discount: Yup.number().required('Discount is required').min(0, 'Discount cannot be negative').max(100, 'Discount cannot be more than 100'),
        // privacy: Yup.string().required('Privacy is required'),
        image: Yup.array()
            
            .test(
              "minLength",
              "You must upload at least 2 images",
              (value) => value && value.length >= 2
            )
            .test(
              "maxLength",
              "You can upload a maximum of 4 images",
              (value) => value && value.length <= 4
            )
      })
    ).required('At least one variant is required').min(1, 'At least one variant is required'),
    });

  return (
    <div className="edit-section">
      <div className="page_name_heading_cont">
        <div className="container">
          <h5>Edit Product</h5>
        </div>
      </div>
      <div className="py-5">
        <div className="container">
          <div className="edit-form shadow-box">
            {
              !loading && 
            
            <Formik
            validationSchema={validationscheme}
              initialValues={
                initialValue
              }
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form>
                  <div className="row">
                  < div className="col-md-6 mb-4">
                      <label htmlFor="productName">Product Name</label>
                      <Field
                        className="form-control"
                        placeholder="Product Name"
                        name="productName"
                        
                      />
                      <ErrorMessage name='productName' component="div" className="text-danger" />
                    </div>
                    
                    <div className="col-md-6 mb-4">
                      <label htmlFor="category">Category</label>
                      <Field
                        className="form-control"
                        as="select"
                        name="category"
                        
                      >
                        <option value="" label="Select Category" />
                        {categorylist.map((res, i) => (
                          <option
                            value={res.id}
                            label={res.category_name}
                            key={i}
                          />
                        ))}
                      </Field>
                      <ErrorMessage name='category' component="div" className="text-danger" />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label htmlFor="vendor">Vendor</label>
                      <Field
                        className="form-control"
                        as="select"
                        name="vendor"
                        
                      >
                        <option value="" label="Select Vendor" />
                        {vendorList.map((res, i) => (
                          <option value={res.id} label={res.name} key={i} />
                        ))}
                      </Field>
                      <ErrorMessage name='vendor' component="div" className="text-danger" />
                    </div>
                    <div className="col-md-12 mb-4">
                      <label htmlFor="description">Description</label>
                      <Field
                        className="form-control"
                        name="description"
                        component="textarea"
                        rows="4"
                        placeholder="Description"
                      />
                      <ErrorMessage name='description' component="div" className="text-danger" />
                    </div>
                  </div>

                  <h3>Variants</h3>
                  <FieldArray name="variantRow">
                    {({ insert, remove, push }) => (
                      <div className="variant-main">
                        {formik.values.variantRow.map((variant, i) => (
                          <div className="variant-row mb-3" key={i}>
                            <div className="row gx-3">
                              <div className="col mb-4">
                                <label htmlFor={`variantRow.${i}.gender`}>
                                  Gender
                                </label>
                                <Field
                                  className="form-control"
                                  as="select"
                                  name={`variantRow.${i}.gender`}
                                  
                                >
                                  <option value="" label="Select Gender" />
                                  <option value="Male" label="Male" />
                                  <option value="Female" label="Female" />
                                  <option value="Unisex" label="Unisex" />
                                </Field>
                                <ErrorMessage name={`variantRow.${i}.gender`} component="div" className="text-danger" />
                              </div>
                              <div className="col mb-4">
                                <label htmlFor={`variantRow.${i}.color`}>
                                  Color
                                </label>
                                <Field
                                  className="form-control"
                                  as="select"
                                  name={`variantRow.${i}.color`}
                                  
                                >
                                  <option value="" label="Select Color" />
                                  {colorList.map((res, j) => (
                                    <option
                                      value={res.id}
                                      label={res.color_name}
                                      key={j}
                                    />
                                  ))}
                                </Field>
                              </div>
                              <div className="col mb-4">
                                <label htmlFor={`variantRow.${i}.size`}>
                                  Size
                                </label>

                                <MultiSelect
                                  options={optionsSize}
                                  name={`variantRow.${i}.size`}
                                  value={formik.values.variantRow[i].size}
                                  onChange={(selected) =>
                                    formik.setFieldValue(
                                      `variantRow.${i}.size`,
                                      selected
                                    )
                                  }
                                  labelledBy="Size"
                                />
                              </div>
                              <div className="col mb-4">
                                <label htmlFor={`variantRow.${i}.weight`}>
                                  Weight
                                </label>
                                <Field
                                  className="form-control"
                                  placeholder="Weight"
                                  name={`variantRow.${i}.weight`}
                                  
                                />
                                <ErrorMessage name={`variantRow.${i}.weight`} component="div" className="text-danger" />
                              </div>
                              <div className="col mb-4">
                                <label htmlFor={`variantRow.${i}.upc`}>
                                  UPC
                                </label>
                                <Field
                                  className="form-control"
                                  placeholder="UPC"
                                  name={`variantRow.${i}.upc`}
                                  
                                />
                                <ErrorMessage name={`variantRow.${i}.upc`} component="div" className="text-danger" />
                              </div>
                              <div className="col mb-4">
                                <label htmlFor={`variantRow.${i}.sku`}>
                                  SKU
                                </label>
                                <Field
                                  className="form-control"
                                  placeholder="SKU"
                                  name={`variantRow.${i}.sku`}
                                  
                                />
                                <ErrorMessage name={`variantRow.${i}.sku`} component="div" className="text-danger" />
                              </div>
                              <div className="col mb-4">
                                <label htmlFor={`variantRow.${i}.price`}>
                                  Price
                                </label>
                                <Field
                                  className="form-control"
                                  placeholder="Price"
                                  name={`variantRow.${i}.price`}
                                  
                                />
                                <ErrorMessage name={`variantRow.${i}.price`} component="div" className="text-danger" />
                              </div>
                              <div className="col mb-4">
                                <label htmlFor={`variantRow.${i}.quantity`}>
                                  Quantity
                                </label>
                                <Field
                                  className="form-control"
                                  placeholder="Quantity"
                                  name={`variantRow.${i}.quantity`}
                                  
                                />
                                <ErrorMessage name={`variantRow.${i}.quantity`} component="div" className="text-danger" />
                              </div>
                              <div className="col mb-4">
                                <label htmlFor={`variantRow.${i}.discount`}>
                                  Discount
                                </label>
                                <Field
                                  className="form-control"
                                  placeholder="Discount"
                                  name={`variantRow.${i}.discount`}
                                  
                                />
                                <ErrorMessage name={`variantRow.${i}.discount`} component="div" className="text-danger" />
                              </div>
                              <div className="col mb-4">
                                <label htmlFor={`variantRow.${i}.privacy`}>
                                  Privacy
                                </label>
                                <Field
                                  className="form-control"
                                  as="select"
                                  name={`variantRow.${i}.privacy`}
                                  
                                >
                                  <option value="" label="Select Policy" />
                                  <option value="1" label="No Return" />
                                  <option value="2" label="7 Days Return" />
                                </Field>
                                <ErrorMessage name={`variantRow.${i}.privacy`} component="div" className="text-danger" />
                              </div>

                              <div className="col mb-4">
                                <label htmlFor={`variantRow.${i}.limit`}>
                                  Purchase Limit
                                </label>
                                <Field
                                  type="number"
                                  className="form-control"
                                  placeholder="Purchase Limit"
                                  name={`variantRow.${i}.limit`}
                                />
                              </div>

                              <div className="col-md-12">
                                <div className="gallery-popup-row">
                                  <div className="add-plus-btn">
                                    <label htmlFor={`variantRow.${i}.image`}>
                                      <span>
                                        <FaUpload />
                                      </span>
                                      {/* <h6>(4/4)</h6> */}
                                    </label>
                                    <input
                                      id={`variantRow.${i}.image`}
                                      type="file"
                                      multiple
                                      accept=".jpg,.jpeg,.png"
                                      onChange={(e) => {
                                        const files = Array.from(
                                          e.target.files
                                        );
                                        const fileURLs = files.map((file) => URL.createObjectURL(file));
                                        formik.setFieldValue(`variantRow.${i}.imagePreview`, fileURLs);
                                        formik.setFieldValue(
                                          `variantRow.${i}.image`,
                                          files
                                        );
                                      }}
                                    />
                                  </div>
                                  <ul className="list-inline upload_image_thumbs ">
                                    {
                                        formik.values.variantRow[i].imagePreview?.map((image , imageIndex)=>(
                                            <>
                                            <li className="list-inline-item   " key={imageIndex} >
                                            <div className="">
                                              <img
                                                className="img-fluid rounded"
                                                src={image}
                                                alt="example"
                                              />
                                            </div>
                                          <TiDeleteOutline className="delete_icons"  onClick={() => {
                                           const updatedVariantRow = [...formik.values.variantRow];
                                              updatedVariantRow[i].image.splice(imageIndex, 1);
                                              formik.setFieldValue('variantRow', updatedVariantRow);} }/>

                                          </li>
                                          </>

                                        ))
                                    }
                            
                                   
                                  </ul>
                                  <ErrorMessage name={`variantRow.${i}.image`} component="div" className="text-danger" />
                                </div>
                              </div>
                              <div className="variant-row-remove">
                                {formik.values.variantRow.length > 1 && (
                                  <button
                                    type="button"
                                    onClick={() => remove(i)}
                                  >
                                    <IoIosCloseCircleOutline />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="add-btn-row">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() =>
                              push({
                                color: "",
                                size: [],
                                upc: "",
                                sku: "",
                                price: "",
                                quantity: "",
                                discount: "",
                                image: [],
                              })
                            }
                          >
                            Add More Variant
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <div className="row">
                    <div className="col-md-3">
                      <button
                        type="submit"
                        name="back"
                        className="btn btn-success w-100 mt-4"
                      >
                        Submit
                      </button>
                    </div>
                    <div className="col-md-3">
                      <button
                        type="button"
                        className="btn btn-secondary w-100 mt-4"
                        onClick={() =>
                          navigate("/products", {
                            state: { previousPageCount },
                          })
                        }
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditProduct;
